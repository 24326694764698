<template>
<div class="index">
  <div class="banner">
    <router-link to="/dragon_star/sign_up"><img class="bannerImg" src="/img/2022/banner.jpg" alt=""></router-link></div>
  <div class="bgGreen">
    <Row :gutter="50">
      <Col span="6" class="my-col-span-5">
        <router-link class="itemBlock" to="/dragon_star/about/guide">
          <span class="infor icon5"></span>
          <p>参赛对象</p>
        </router-link>
      </Col>
      <Col span="6" class="my-col-span-5">
        <router-link class="itemBlock" to="/dragon_star/about/guide">
          <span class="infor icon6"></span>
          <p>参赛指南</p>
        </router-link>
      </Col>
      <Col span="6" class="my-col-span-5">
        <router-link class="itemBlock" to="/dragon_star/about/award_setting">
          <span class="infor icon7"></span>
          <p>奖项设置</p>
        </router-link>
      </Col>
      <Col span="6" class="my-col-span-5">
        <router-link class="itemBlock" to="/dragon_star/about/download">
          <span class="infor icon8"></span>
          <p>下载参赛资料</p>
        </router-link>
      </Col>
      <Col span="6" class="my-col-span-5">
        <router-link class="itemBlock" to="/certificate">
          <span class="infor icon9"></span>
          <p>下载证书</p>
        </router-link>
      </Col>
    </Row>
    <h1 class="title">参 赛 类 别<span class="en">Category</span></h1>
    <Row :gutter="50" type="flex" align="middle" justify="center">
      <Col span="8">
        <router-link class="itemBlock divBlock" to="/dragon_star/about/award_type?id=a">
          <div class="left">
          <p class="bg">A</p>
          <p class="text">主题赛</p>
          </div>
          <div class="right">
            <p>绿色人居类</p>
          </div>
        </router-link>
      </Col>
      <Col span="8">
        <router-link class="itemBlock divBlock" to="/dragon_star/about/award_type?id=b">
          <div class="left">
            <p class="bg">B</p>
            <p class="text">主题赛</p>
          </div>
          <div class="right">
            <p>绿色产品类</p>
          </div>
        </router-link>
      </Col>
      <Col span="8">
        <router-link class="itemBlock divBlock" to="/dragon_star/about/award_type?id=c">
          <div class="left">
            <p class="bg">C</p>
            <p class="text">主题赛</p>
          </div>
          <div class="right">
            <p>绿色视觉传达类</p>
          </div>
        </router-link>
      </Col>
    </Row>
    <Row :gutter="50" type="flex" align="middle" justify="center">
      <Col span="8">
        <router-link class="itemBlock divBlock" to="/dragon_star/about/award_type?id=d">
          <div class="left">
            <p class="bg">D</p>
            <p  class="text">命题赛</p>
          </div>
          <div class="right">
            <p>校园绿色空间设计</p>
          </div>
        </router-link>
      </Col>
      <Col span="8">
        <router-link class="itemBlock divBlock" to="/dragon_star/about/award_type?id=e">
          <div class="left">
            <p class="bg">E</p>
            <p  class="text">命题赛</p>
          </div>
          <div class="right">
            <p>邢台平乡童车、儿童电动玩具绿色创新设计</p>
          </div>
        </router-link>
      </Col>
      <Col span="8">
        <router-link class="itemBlock divBlock" to="/dragon_star/about/award_type?id=f">
          <div class="left">
            <p class="bg">F</p>
            <p  class="text">命题赛</p>
          </div>
          <div class="right">
            <p>儿童友好型绿色设计产品</p>
          </div>
        </router-link>
      </Col>
    </Row>
  </div>
  <div class="bgWorld">
    <h1 class="title">大 赛 赛 程<span class="en">Schedule</span></h1>
    <img class="img" src="/img/2022/dssc.jpg" alt="">
  </div>
  <div class="bgWhite">
    <!-- <h1 class="title">组 织 机 构<span class="en">Organization</span></h1> -->
     <img class="img" src="/img/2022/zzjg.jpg" alt="">
     <!-- <h1 class="title">信 息 公 示<span class="en">Publicity</span></h1>
     <Row :gutter="50">
       <Col span="6" class="center">
         <router-link class="itemBlock" to="/dragon_star/about/organization">
           <span class="infor icon1"></span>
           <p>组织机构</p>
         </router-link>
       </Col>
       <Col span="6" class="center">
         <router-link class="itemBlock" to="/dragon_star/about/feedback">
           <span class="infor icon2"></span>
           <p>监督反馈</p>
         </router-link>
       </Col>
       <Col span="6" class="center">
         <router-link class="itemBlock" to="/dragon_star/about/exemption">
           <span class="infor icon3"></span>
           <p>免责须知</p>
         </router-link>
       </Col>
       <Col span="6" class="center">
         <router-link class="itemBlock" to="/dragon_star/about/publicity">
           <span class="infor icon4"></span>
           <p>获奖公示</p>
         </router-link>
       </Col>
     </Row> -->
  </div>
</div>
</template>

<script>
export default {
  methods: {
    showNote() {
      this.$parent.toSignUp();
    }
  }
};
</script>

<style lang="scss" scoped>
.index{
  .banner{
    line-height: 0;
    .bannerImg{
      width: 100%;
    }
  }
  .en{
    position: absolute;
    top: 10px;
    color: #78C651;
    opacity: .5;
    left: 0;
    right: 0;
    font-size: 1.4em;
    z-index: -1;
  }
  .infor{
    display: inline-block;
    width: 40px;
    height: 40px;
    background: no-repeat center;
    background-size: contain;
  }
  .icon1{
    background-image: url(/img/2021/icon1.png);
  }
  .icon2{
    background-image: url(/img/2021/icon2.png);
  }
  .icon3{
    background-image: url(/img/2021/icon3.png);
  }
  .icon4{
    background-image: url(/img/2021/icon4.png);
  }
  .icon5{
    background-image: url(/img/2021/icon5.jpg);
  }
  .icon6{
    background-image: url(/img/2021/icon6.jpg);
  }
  .icon7{
    background-image: url(/img/2021/icon7.jpg);
  }
  .icon8{
    background-image: url(/img/2021/icon8.jpg);
  }
  .icon9{
    background-image: url(/img/2021/icon9.jpg);
  }
  .bgGreen{
    background: #78C651;
    padding: 80px 50px;
    .my-col-span-5{
      flex: 0 0 20%;
      max-width: 20%;
    }
    .itemBlock{
      display: block;
      background: #ffffff;
      border-radius: 15px;
      text-align: center;
      color: #3E3A39;
      padding: 30px;
      font-weight: 600;
      font-size: 16px;
      .blockIcon{
        font-size: 40px;
        color: #016934;
      }
    }
    .title{
      padding: 50px 0;
      text-align: center;
      color: #ffffff;
      position: relative;
      .en{
        top: 60px;
        color: #5FB84C;
        z-index: 0;
      }
    }
    .divBlock{
      position: relative;
      height: 160px;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      .bg{
        position: absolute;
        font-size: 90px;
        z-index: 1;
        color: #c0dfb2;
      }
      .text{
        z-index: 2;
        zoom: 1;
        font-size: 20px;
      }
      .left{
        display: flex;
        justify-content: center;
        width: 100px;
      }
      .right{
        flex: 1;
        padding-left: 30px;
        text-align: left;
        font-weight: normal;
      }
    }
  }
  .bgWorld{
    padding: 80px 50px;
    text-align: center;

    .title{
      padding-bottom: 50px;
      color: #3E3A39;
      position: relative;
    }
  }
  .img{
    max-width: 90%;
  }
  .bgWhite{
    // background: #78C651;
    padding: 0 50px 80px;
    text-align: center;

    .title{
      padding-bottom: 50px;
      color: #3E3A39;
      position: relative;
    }
    
    // .itemBlock{
    //   display: inline-block;
    //   text-align: center;
    //   color: #3E3A39;
    //   margin: 30px 0;
    //   font-weight: 600;
    // }
  }
}
</style>